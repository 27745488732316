// General Page Section
export const HOME_PAGE = '/';
export const AGENTS_PAGE = '/agents';

// Listing Single Page Section
export const LISTING_POSTS_PAGE = '/listing';
export const SINGLE_LOCATION_PAGE = '/location';

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = '/profile';
export const AGENT_ACCOUNT_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = '/edit';
export const AGENT_IMAGE_EDIT_PAGE = '/change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = '/change-password';
export const AGENT_PROFILE_DELETE = '/delete';
export const AGENT_PROFILE_FAVOURITE = '/favourite-post';
export const AGENT_PROFILE_CONTACT = '/contact';

// Other Pages
export const PRICING_PLAN_PAGE = '/pricing-plan';
export const PRIVACY_PAGE = '/privacy';
export const ADD_HOTEL_PAGE = '/add-hotel';

// Login / Registration Page
export const LOGIN_PAGE = '/sign-in';
export const REGISTRATION_PAGE = '/sign-up';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';

// Jurčių sodyba Pages
export const HOUSE_LIST = '/location-list';
export const CONTACTS_PAGE = '/contacts'

// Local resources types
export const LOCAL_RESOURCES_TYPES = Object.freeze({
  locations: 'locations',
  location: 'location',
});

// Amenities
export const AMENITIES = Object.freeze({
  //Indoors
  wifi: 'wifi',
  parking: 'parking',
  shower: 'shower',
  kitchen: 'kitchen',
  grillPlace: 'grillPlace',
  outsidePlace: 'outsidePlace',
  toilet: 'toilet',
  dishwasher: 'dishwasher',
  tv: 'tv',
  fireplace: 'fireplace',
  //Outdoors
  forest: 'forest',
  tennis: 'tennis',
  outdoorTennis: 'outdoorTennis',
  volleyBall: 'volleyBall',
  footBall: 'footBall',
  basketBall: 'basketBall',
  swimming: 'swimming',
  carouseles: 'carouseles',
  darts: 'darts',
  training: 'training',
  boardGames: 'boardGames',
  waterTransport: 'waterTransport',
  bicycles: 'bicycles',
  hotTub: 'hotTub',
});

export const AMENITIES_SELECTION = [
  { label: 'WiFi', value: 0, amentitiesValue: 'wifi' },
  { label: 'Parkingo vieta', value: 1, amentitiesValue: 'parking' },
  { label: 'Dušas', value: 2, amentitiesValue: 'shower' },
  { label: 'Virtuvė', value: 3, amentitiesValue: 'kitchen' },
  { label: 'Grilio vieta', value: 4, amentitiesValue: 'grillPlace' },
  { label: 'Terasa', value: 5, amentitiesValue: 'outsidePlace' },
  { label: 'Tualetas', value: 6, amentitiesValue: 'toilet' },
  { label: 'Indaplovė', value: 7, amentitiesValue: 'dishwasher' },
  { label: 'Televizija', value: 8, amentitiesValue: 'tv' },
  { label: 'Židinys', value: 9, amentitiesValue: 'fireplace' },
];
